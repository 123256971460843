$('.building').resizable({
  handles: "n",
  minHeight: 80,
  maxHeight: 540,
  grid: 80,
});

$('.building').resize(function() {
 stop();
});


const menu_bt = ["bt-1 open","", "bt-2 open","", "bt-3 open","","","", "bt-4 open"];
let classFloor = 0;
const stop = () => {
  let buildingHeight = $('.building').outerHeight();
  
  let floorHeight = $('.floor').outerHeight();
  // check how many floors fit the building ... Math.floor ( ͡° ͜ʖ ͡°)
  let floors = Math.floor(buildingHeight / floorHeight);
  let currentFloors = $('.building .floor').length;
  
  // get the difference
  let diff = floors - currentFloors;
  //console.log(buildingHeight,floorHeight,floors,currentFloors,diff);
  
  if (diff < 0) {
    diff = -diff;
    for (let i = 0; i < diff; i++) {
      if(currentFloors>1){
        classFloor--;
        // remove top floor
        $('.building').find('.floor:first').remove();
      }
    }
  } else {
    console.log(diff);
    for (let i = 0; i < diff; i++) {
      classFloor++;
      const floor = '<div class="floor justify-content-between floor-'+classFloor+'"><div class="window"></div><div class="window"></div><div class="window"></div><div class="window"></div><div class="window"></div></div>';
      $('.building').prepend(floor);
    }
  }

  // randomly open windows
  $('.window').each(function() {
    $(this).removeClass(menu_bt[~~(Math.random()*menu_bt.length)]);
    $(this).addClass(menu_bt[~~(Math.random()*menu_bt.length)]);
  })
}

// Particles by Zed Dash https://codepen.io/z-/pen/bpxgWZ
const initparticles = () => leaves();

const rnd = (m, n) => {
return Math.floor(Math.random() * (parseInt(n) - parseInt(m) + 1)) + parseInt(m);
}

const leaves = () => {
$('.tree').each(function() {
  let count = ($(this).width() / 20) * 5;
  for (let i = 0; i <= count; i++) {
    let size = (rnd(60, 120) / 10);
    $(this).append(`<span class="particle" style="left:${rnd(-70, 70)}px;width:${size}px;height:${size}px;animation-delay:${(rnd(0, 30) / 10)}s;"></span>`);
  }
});
}

initparticles();


// hide ui
let uis = 0;
const keydown = (e) => {
  let key = e.which;

  if (key === 32) {
    if (uis == 0) {
      $('.ui').css('opacity', '0');
      uis = 1;
    } else {
      $('.ui').css('opacity', '1');
      uis = 0;
    }
  }
}

$(document).on('keydown', keydown);
$(document).ready(function() {
  $("#modalConstrucao").modal('show');
  
});

$('.closeModal').on('click', function() {
  fechaModal()
})
function fechaModal() {
  $("#modalConstrucao").toggleClass(function(n){
    $("#modalConstrucao").removeClass('d-flex')
    return "d-none";
  });
}